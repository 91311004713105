import React, { useEffect, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserLogin } from "../context/AuthContext";
import { TourProvider } from "@reactour/tour";
import { stepsEnglish, stepsSpanish, stepsDutch, stepsPapiamento } from "../components/steps";
import ProtectedRoute from "./ProtectedRoute";

// Lazy load the components
const Login = React.lazy(() => import("../components/AuthScreens/Login"));
const Register = React.lazy(() => import("../components/AuthScreens/Signup"));
const OTPVerification = React.lazy(() => import("../components/AuthScreens/OTPVerification"));
const RegisterPhone = React.lazy(() => import("../components/AuthScreens/RegisterPhone"));
const VerificationDone = React.lazy(() => import("../components/AuthScreens/VerificationDone"));
const InterviewHome = React.lazy(() => import("../components/InterviewScreens/InterviewHome"));
const InterviewWarmUp = React.lazy(() => import("../components/InterviewScreens/InterviewWarmUp"));
const InterviewQs = React.lazy(() => import("../components/InterviewScreens/InterviewQs"));
const Reports = React.lazy(() => import("../components/MyReports/Reports"));
const SelectedReport = React.lazy(() => import("../components/MyReports/SelectedReport"));
const PISecondScreen = React.lazy(() => import("../components/PredictiveIndex/PISecondScreen"));
const PIThirdScreen = React.lazy(() => import("../components/PredictiveIndex/PIThirdScreen"));
const PIFourthScreen = React.lazy(() => import("../components/PredictiveIndex/PIFourthScreen"));
const PIFivthScreen = React.lazy(() => import("../components/PredictiveIndex/PIFivthScreen"));
const PILastScreen = React.lazy(() => import("../components/PredictiveIndex/PILastScreen"));
const Contact = React.lazy(() => import("../components/ContactUs/Contact"));
const FAQScreen = React.lazy(() => import("../components/FAQ/FAQScreen"));
const Main = React.lazy(() => import("../components/Home/Main"));
const CVForm = React.lazy(() => import("../components/CV/CVForm"));
const FPScreen1 = React.lazy(() => import("../components/AuthScreens/ForgetPassword/FP_Screen1"));
const FPScreen3 = React.lazy(() => import("../components/AuthScreens/ForgetPassword/FP_Screen3"));
const FPScreen2 = React.lazy(() => import("../components/AuthScreens/ForgetPassword/FP_Screen2"));
const ErrorPage = React.lazy(() => import("../components/AuthScreens/ErrorPage"));
const Terms = React.lazy(() => import("../components/T&C/Terms"));
const PrivacyPolicy = React.lazy(() => import("../components/T&C/PrivacyPolicy"));
const InterviewOrgTable = React.lazy(() => import("../components/InterviewScreens/InterviewOrgTable"));
const PIReportMain = React.lazy(() => import("../components/MyReports/PredictiveReport/PIReportMain"));
const MainRoute = React.lazy(() => import("../components/LandingWebsite/MainRoute"));
const PrivateRoutes = React.lazy(() => import("./PrivateRoutes"));

function Stack() {
  const { showToast, setIsErrorOpen, setToken, langChange,barStatus } = UserLogin();

  const getInitialSteps = () => {
    switch (langChange) {
      case "SP":
        return stepsSpanish;
      case "DU":
        return stepsDutch;
      case "PA":
        return stepsPapiamento;
      case "EN":
      default:
        return stepsEnglish;
    }
  };

  let navigation = useNavigate();
  const finalSteps = getInitialSteps();

  useEffect(() => {
    let idleTimer;
    const timeoutDuration = 2 * 60 * 60 * 1000;

    const resetTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("organizationUrl");
        setToken("");
        showToast("You have been logged out due to inactivity.");
        setIsErrorOpen(true);
        navigation("/", { replace: true });
      }, timeoutDuration);
    };

    const handleUserInteraction = () => {
      resetTimer();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    resetTimer();

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      clearTimeout(idleTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TourProvider steps={finalSteps} showBadge={false} showTooltip={false} showTooltipArrow={false}>
      <div style={{ fontFamily: "Roboto, sans-serif" }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route exact path="/Main" element={<Main />} />
              <Route path="/CVForm"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={1}>
                    <CVForm />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/InterviewHome"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={[2,3,4]}>
                    <InterviewHome />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/InterviewWarmUp"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={[2,3,4]}>
                    <InterviewWarmUp />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/InterviewQs"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={[2,3,4]}>
                    <InterviewQs />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/PISecondScreen"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={3}>
                    <PISecondScreen />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/PIThirdScreen"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={3}>
                    <PIThirdScreen />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/PIFourthScreen"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={3}>
                    <PIFourthScreen />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/PIFivthScreen"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={3}>
                    <PIFivthScreen />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/PILastScreen"
                element={
                  <ProtectedRoute progressStatus={barStatus} requiredStatus={3}>
                    <PILastScreen />
                  </ProtectedRoute>
                }
              />

              <Route exact path="/Reports" element={<Reports />} />
              <Route exact path="/SelectedReport" element={<SelectedReport />} />
              {/* <Route exact path="/PIThirdScreen" element={<PIThirdScreen />} />
              <Route exact path="/PIFourthScreen" element={<PIFourthScreen />} />
              <Route exact path="/PIFivthScreen" element={<PIFivthScreen />} />
              <Route exact path="/PILastScreen" element={<PILastScreen />} /> */}
              <Route exact path="/Contact" element={<Contact />} />
              <Route exact path="/FAQScreen" element={<FAQScreen />} />
              <Route exact path="/interview_org_table" element={<InterviewOrgTable />} />
              <Route exact path="/predictive_report" element={<PIReportMain />} />
            </Route>

            <Route path="/" element={<MainRoute />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget_password" element={<FPScreen1 />} />
            <Route path="/forget_otp_password" element={<FPScreen2 />} />
            <Route path="/reset_password" element={<FPScreen3 />} />
            <Route path="/otp_verificaion" element={<OTPVerification />} />
            <Route path="/register_phone" element={<RegisterPhone />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/verification_done" element={<VerificationDone />} />
            <Route exact path="/tnc" element={<Terms />} />
            <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route exact path="/error" element={<ErrorPage />} />
            <Route exact path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </div>
    </TourProvider>
  );
}

export default Stack;
