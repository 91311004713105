import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, requiredStatus, progressStatus }) => {
    if (progressStatus === undefined) {
        console.log("progressStatus is undefined");
        return <Navigate to="/Main" replace />
    }
    const isAllowed =
        Array.isArray(requiredStatus)
            ? requiredStatus.includes(Number(progressStatus))
            : Number(progressStatus) === requiredStatus || progressStatus === requiredStatus;

    if (isAllowed) {
        return children;
    }
    console.log(requiredStatus, progressStatus);
    return <Navigate to="/Main" replace />;
};

export default ProtectedRoute;
